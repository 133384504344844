body {
  background-color: white;
  font-family: Roboto, Helvetica, Arial, sans-serif
}

.AppBarSpacer {
  height: 70px;
}

.App .MuiList-root {
  width: 100%;
  max-width: none;
}

.App .MuiListSubheader-root {
  width: 100%;
  max-width: none;
}

.App .MuiFormControl-root {
  width: 100%;
  max-width: none;
}

.DrawerContentContainer {
  padding: 16px;
}

.Page {
  padding: 16px;
  overflow: auto;
  min-height: calc(100vh - 100px);
}

/* .Root {
  
} */

.Page .Required label:after {
  content: "*";
  padding-left: 10px;
  padding-right: 7px;
  color: red;
  background-color: white;
}

.Page .LoginErrorMessage {
  display: flex;
  justify-content: center;
  color: red;
}

.ScrollView {
  overflow: auto
}

.App .Loading .LoadingProgress {
  display: flex;
  justify-content: center;
}
.App .Loading .LoadingText {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.App .Center {
  display: flex;
  justify-content: center;
}

.App .UpdatedLabel {
  margin-top: 5px;
}

.App .UpdatedLabel svg {
  vertical-align: middle;
  margin-right: 5px;
}

.App .UpdatedLabel span {
  vertical-align: middle;
}

.App .GroupTab {
  /* display: flex;
  flex-direction: column; */
  height: calc(100vh - 110px);
  /* background-color: aqua; */
  margin-top: -10px;
  border: 1px solid gray;
  overflow: auto;
  padding: 0;
}


.App .GroupTabTitleContainer {
  margin-top: -10px;
  z-index: 9999;
}

.App .GroupTabTitle {
  background-color: white;
  margin-left: 15px;
  padding-left: 8px;
  padding-right: 8px;
}

.App .GroupTabContent {
  margin-top: 8px;
}

.App .ListItem {
  margin-bottom: 10px;
}

.App .RadioGroupListBeforeAdd {
  height: calc(100vh - 190px);
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.App .RadioGroupListForHome {
  /* height: 190px; */
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.App .RadioGroupAddContainer {
  position: relative;
  height: 50px;
}

.App .RadioGroupAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  padding-top: 16px;
  width: 100%;
}

.DrawerHeaderContainer {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.DrawerCartContainer {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.SideBarCartWidget {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  overflow: hidden;
  width: 100%;
  height: 250px;
  background-color: #317bc4;
  border-radius: 10px;
}

.SideBarCartWidgetHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 10px;
  padding-left: 10px;
}

.SideBarCartWidget .CartItemsList {
  background-color: #e9e9e9;
  margin: 10px;
  height: 120px;
  overflow: auto;
  border-radius: 5px;
}

.MuiDrawer-paper {
  overflow-y: hidden !important;
}

.DrawerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  background-color: #1976d2;
}

.DrawerHeaderSpacer {
  margin-top: 120px;
}

.DrawerHeaderContent {
  color: white;
}

.DrawerHeaderDivider {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 0.5px solid white;
}

.DrawerContentContainer {
  overflow: inherit;
  height: calc(100vh - 340px);
}

.SideBar {
  width: 320px;
  overflow: hidden;
}

.EndSideBar {
  width: 300px;
  overflow: hidden;
}

.EndDrawerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #1976d2;
}

.EndDrawerHeaderContainer {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.EndDrawerHeaderSpacer {
  margin-top: 50px;
}

.EndDrawerHeaderContent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;  
}

.EndDrawerContentContainer {
  padding: 16px;
  overflow: auto;
  height: calc(100vh - 80px);
}

.EquipmentListContainer {
  /* overflow-x: auto; */
  /* overflow-y: scroll; */
  border: 1px solid gray;
  padding-top: 10px;
  padding-bottom: 16px;
  padding-right: 10px;
}

.EquipmentListContainerForHome {
  /* overflow-x: auto; */
  /* overflow-y: scroll; */
  border: 1px solid gray;
  padding-top: 10px;
  padding-bottom: 16px;
  padding-right: 10px;
}

.IconPickerContainer div[class^="sc-"] {
  margin-left: 160px;
}

.IconPickerContainer > div {
  margin-left: 0 !important;
}

.FaIconContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.FaIconContainer div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.MenuItemWithIcon {
  display: flex;
  align-items: center;
}

.MenuItemWithIcon > div {
  display: flex;
  align-items: center;
}

.RentalStatus {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  width: 50px;
  display: inline-block;
  text-align: center;
}

.RequiredDatePickerContainer label:after {
  content: "*";
  padding-left: 10px;
  padding-right: 7px;
  color: red;
  background-color: white;
}

.EquipmentsView {
  border-radius: 10px;
  border: 1px solid gray;
}

.EquipmentsViewHeader {
  background-color: rgb(197, 197, 197);
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.RentalEquipmentsViewHeader {
  background-color: #f8a663;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px;
  border-radius: 10px 10px 0 0;
  position: relative;
}

@media (max-width:500px) {
  .EquipmentsViewHeader {
    padding-bottom: 60px;
  }

  .RentalEquipmentsViewHeader {
    padding-bottom: 60px;
  }


  .EquipmentsViewHeaderCartButton {
    top: 50px;
  }
}

.EquipmentsViewHeaderCartButton {
  display: inline-block;
  right: 10px;
  position: absolute;
}

.EquipmentsViewReturned {
  background-color: rgb(90, 193, 106);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px;
  display: flex;
  align-items: center;
}

.EquipmentViewReadableName {
  display: flex;
  /* justify-content: start; */
  align-items: center;
}

.RentalLogView {
  border-radius: 10px;
  border: 1px solid gray;
}

.RentalLogViewContent {
  max-height: 700px;
  overflow: auto;
}

.RentalLogViewHeader {
  /* width: 100%; */
  background-color: rgb(197, 197, 197);
  display: flex;
  justify-content: start;
  align-items: center;
  /* vertical-align: middle; */
  padding: 16px;
  border-radius: 10px 10px 0 0;
}

.Page .RentalLogViewItemContainer {
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: start;
}

.Page .RentalLogViewItem {
  /* display: block; */
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: start;
  min-height: 30px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.Page .RentalLogViewRentalType{
  display: flex;
  justify-content: center;
  width: 60px;
}

.Page .RentalLogViewRentalSpan {
  background-color: rgb(197, 197, 197);
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  margin-left: 5px;
  margin-right: 5px;
}

.Page .RentalLogViewReturnedDate {
  background-color: rgb(197, 197, 197);
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  margin-left: 5px;
  margin-right: 5px;
}

.Page .RentalLogViewItemDetail {
  /* display: block; */
  display: flex;
  flex-grow: 1;
  justify-content: start;
  align-items: center;
  height: 34px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.Page .RentalLogViewItemDetail > div {
  margin-left: 40px;
  display: flex;
  flex-grow: 1;
  justify-content: start;
  align-items: center;
}

.Page .RentalLogViewNotReturned {
  color: red;
}

.Page .RentalLogViewNotTransferred {
  color: orange;
}

.Page .RentalLogViewReturned svg {
  color: green;
}

.Page .RentalLogViewReturned .RentalLogViewReturnedStatus {
  color: green;
}

.FilterForm > .MuiTextField-root  {
  width: 100%;
}

.FilterForm > .MuiSelect-root  {
  width: 100%;
}

.FilterForm .InputLabel {
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
}

.AlignMiddle {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.QRReaderContainerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.QRReaderContainer {
  display: block;
  min-width: 400px;
  min-height: 400px;
}

.CheckResult.Loading {
  color: orange
}

.CheckResult.Error {
  color: red
}

.CheckResult.OK {
  color: green
}

.PageContainer {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}